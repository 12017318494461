<template>
    <div><offer-list :locale="locale" :reload="reload" @finish-reload="finishReload" /></div>
    <div>
        <product-filter
            :locale="locale"
            :q="q"
            :searched-category="searchedCategory"
            :searched-subcategory="searchedSubcategory"
            :searched-application="searchedApplication"
            :advanced-search="advancedSearch"
            @start-reload="startReload"
        />
    </div>
</template>

<script>
export default {
    name: "ProductsPage",
    props: {
        locale: {
            required: true,
            type: String,
        },
        q: {
            required: false,
            type: String,
        },
        searchedCategory: {
            required: false,
            type: String,
        },
        searchedSubcategory: {
            required: false,
            type: String,
        },
        searchedApplication: {
            required: false,
            type: String,
        },
        advancedSearch: {
            required: false,
            type: Boolean,
        }
    },
    data() {
        return {
            reload: true
        }
    },
    methods: {
        finishReload() {
            this.reload = false;
        },
        startReload() {
            this.reload = true;
        }
    }
}
</script>

<style scoped>

</style>
