import FilterPage from './components/FilterPage'

// require('bootstrap')
import Swiper from 'swiper'
import 'swiper/swiper-bundle.css'
import SwiperCore, { A11y, Navigation, Pagination, EffectFade, Keyboard, Autoplay, Scrollbar, EffectFlip } from 'swiper/core'
import { createApp } from 'vue'
import ProductFilter from './components/ProductFilter'
import OfferList from './components/OfferList'
import { VueCookieNext } from 'vue-cookie-next'
import ProductsPage from "./components/ProductsPage";
import MobileFilterPage from "./components/mobile/MobileFilterPage";
import MobileProductFilter from "./components/mobile/MobileProductFilter";
import MobileOfferList from "./components/mobile/MobileOfferList";
import MobileProductsPage from "./components/mobile/MobileProductsPage";
import EncryptedContent from "./components/EncryptedContent";

const app = createApp({})
app.use(VueCookieNext)
app.component('product-filter', ProductFilter)
app.component('offer-list', OfferList)
app.component('filter-page', FilterPage)
app.component('products-page', ProductsPage)
app.component('mobile-filter-page', MobileFilterPage)
app.component('mobile-product-filter', MobileProductFilter)
app.component('mobile-offer-list', MobileOfferList)
app.component('mobile-products-page', MobileProductsPage)
app.component('encrypted-content', EncryptedContent)

app.mount('#app')

SwiperCore.use([A11y, Navigation, Pagination, EffectFade, Keyboard, Autoplay, Scrollbar, EffectFlip])

// HOME SLIDER
new Swiper('.mySwiper', {
    speed: 1000,
    effect: 'fade',
    fadeEffect: {
        crossFade: true,
    },
    autoplay: {
        delay: 9000,
        disableOnInteraction: false,
    },

    keyboard: {
        enabled: true,
    },
    pagination: {
        el: '.swiper-pagination',
        clickable: true,

        renderBullet: function (index, className) {
            return '<span class="' + className + '">' + 0 + (index + 1) + '</span>'
        },
    },
})

var swiper = new Swiper('.manufacturing-slider', {
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    effect: 'fade',
    fadeEffect: {
        crossFade: true,
    },
})

// HOME PRODUCTS SLIDER
const homeProductsSlider = new Swiper('.home-products-slider', {
    slidesPerView: 1,
    spaceBetween: 30,
    slidesPerGroup: 1,
    loopFillGroupWithBlank: true,
    keyboardControl: true,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    breakpoints: {
        520: {
            slidesPerView: 2,
            spaceBetween: 20,
        },
        999: {
            slidesPerView: 3,
            spaceBetween: 30,
        },
        1200: {
            slidesPerView: 4,
            spaceBetween: 30,
        },
    },
})
// Update swiper buttons state to fix disabled button problem
$(document).ready(function () {
    homeProductsSlider.navigation.update()
})

//HOME NEWS SLIDER
new Swiper('.home-news-slider', {
    slidesPerView: 1,
    spaceBetween: 30,
    slidesPerGroup: 1,
    loopFillGroupWithBlank: true,
    keyboardControl: true,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    a11y: true,
    breakpoints: {
        520: {
            slidesPerView: 2,
            spaceBetween: 20,
        },
        999: {
            slidesPerView: 3,
            spaceBetween: 30,
        },
        1200: {
            slidesPerView: 4,
            spaceBetween: 30,
        },
    },
})

//OUR HISTORY SLIDER
new Swiper('.our-history-slider', {
    spaceBetween: 160,
    slidesPerGroupSkip: 1,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    keyboard: {
        enabled: true,
    },

    scrollbar: {
        el: '.swiper-scrollbar',
        draggable: true,
    },

    breakpoints: {
        576: {
            slidesPerView: 1,
            spaceBetween: 0,
        },
        999: {
            slidesPerView: 1.6,
            spaceBetween: 120,
        },
        1200: {
            slidesPerView: 2.6,
            spaceBetween: 160,
        },
    },
})

//COMPANY DOING MOBILE SLIDER
new Swiper('.company-doing-mobile-slider', {
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
})

//QUALITY SYSTEMS MOBILE SLIDER
new Swiper('.quality-systems-mobile-slider', {
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
})

//CERTIFICATES MOBILE SLIDER
new Swiper('.certificates-mobile-slider', {
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    effect: 'flip',
})

// JQUERY
$(document).ready(function () {



    //Scroll Animation Actived

    $(window).scroll(function () {
        var scrollTop = $(window).scrollTop()
        $('.anim').each(function (i) {
            var $box = $('.anim').eq(i)
            var offset = $box.offset()
            if (scrollTop > offset.top - $(window).height() / 1.2) {
                $box.addClass('active')
            } else {
                $box.removeClass('active')
            }
        })

        ///Scoll on Flowing
        document.body.style.setProperty('--scroll', window.pageYOffset / (document.body.offsetHeight - window.innerHeight))
    })

    // Loader Page Animation
    $('.loader').addClass('active')

    // MENU TOGGLE
    $('.toggle, .menu-btn').on('click', function () {
        $('.toggle').toggleClass('on-off')
        $('.navbar-screen').toggleClass('active')
        $('.navbar-logo').toggleClass('active')
        $('.close-logo').toggleClass('active')
        $('.close-navbar').toggleClass('active')
        $('.header').toggleClass('active')
        $('.header-container').toggleClass('active')
        $('body').toggleClass('overflow-hidden')
        let path = window.location.pathname
        path = path.split('/');
        if(path[1] === 'en') {
            $('.menu-btn').text($('.menu-btn').text().trim() == 'Menu' ? 'Close' : 'Menu')

        } else {
            $('.menu-btn').text($('.menu-btn').text().trim() == 'Menü' ? 'Kapat' : 'Menü')

        }
    })

    //Navbar Toggle

    function onStartFilter(){
        $('.search-filter').toggleClass('active')
    }
    onStartFilter()

    function onStartOpen(){
        $('.hamburger').toggleClass('active')
        $('.hamburger').toggleClass('filter-menu-active')
        $('.filter-body').toggleClass('active')
    }
    // onStartOpen()

    function filterProduct() {
        $('.hamburger').toggleClass('active')
        $('.hamburger').toggleClass('filter-menu-active')
        $('.filter-body').toggleClass('active')
        $('.search-filter').toggleClass('active')

        $('.filter-body-mobile[data-filter="product-group"]').toggleClass('active')


        $('.f-2').removeClass('active')
        $('.mobile-filter-item').removeClass('active')
        $('.filter-group').removeClass('active')

    }
    $('.filter-button').on('click', filterProduct)



    $('.open-search').on('click', function () {
        $('.search-screen').toggleClass('active')
    })

    $('.fa-info').on('click', function () {
        $(this).parent().parent().parent().parent().addClass('active')
    })

    // $('.list-toggle').on('click', function () {
    //     $('.offer-list-dropdown').toggleClass('active')
    // })

    $('.product-item .fa-info').on('click', function () {
        $(this).parent().parent().toggleClass('active')
    })

    $('.read-more-btn').on('click', function () {
        $(this).parent().addClass('active')
    })

    $('.minus').click(function () {
        var $input = $(this).parent().find('.pieces-input')
        var count = parseInt($input.val()) - 1
        count = count < 1 ? 1 : count
        $input.val(count)
        $input.change()
        return false
    })
    $('.plus').click(function () {
        var $input = $(this).parent().find('.pieces-input')
        $input.val(parseInt($input.val()) + 1)
        $input.change()
        return false
    })


    ///Selected Language
    function selectedValueHidden(getValue){
        $('.language-item').each(function () {
            if (getValue === $(this).text().trim()) {
                $(this).css('display', 'none')
            } else {
                $(this).css('display', 'block')
            }
        })
    }

    selectedValueHidden(document.documentElement.lang.toUpperCase());

    //PersonAccordion
    $(".collapse")
    .on("show.bs.collapse", function () {
        $(this).parents('.col-md-6').addClass('wide')
    })
    .on("hide.bs.collapse", function () {
        $(this).parents('.col-md-6').removeClass('wide')
    });
    $('.collapse').on('shown.bs.collapse', function (e) {
        var $card = $(this).closest('.accordion-col-custom')
        var $open = $($(this).data('parent')).find('.collapse.show')

        var additionalOffset = 0
        if ($card.prevAll().filter($open.closest('.accordion-col-custom')).length !== 0) {
           additionalOffset = $open.height()
        }
        $('html,body').animate(
           {
              scrollTop: $card.offset().top - additionalOffset,
           },
           500
        )
    })

    ///Video Company

    $(document).on('click', '.js-videoPoster, .play-text', function (e) {
        e.preventDefault()
        var poster = $(this)
        var wrapper = poster.closest('.js-videoWrapper')
        videoPlay(wrapper)
    })

    function videoPlay(wrapper) {
        var iframe = wrapper.find('.js-videoIframe')
        var src = iframe.data('src')
        wrapper.addClass('videoWrapperActive')
        iframe.attr('src', src)
    }

    const file = document.querySelector('#resume');
    if (file) {
        file.addEventListener('change', (e) => {
            const [file] = e.target.files;
            const { name: fileName, size } = file;
            const fileSize = (size / 1000).toFixed(2);
            const fileNameAndSize = `${fileName} - ${fileSize}KB`;
            document.querySelector('.file-name').textContent = fileNameAndSize;
        });
    }
})

